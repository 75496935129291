import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import { Helmet } from 'react-helmet';

import Hero from '../components/Hero';
import ArticlePreview from '../components/PageComponents/ArticlePreview';
import Button from '../components/PageComponents/Button';
import PageStyles from '../styles/PageStyles';

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allContentfulBlogPost.edges');

    return (
      <PageStyles location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />
          <div>Blog</div>
          <div className="wrapper">
            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.map(({ node }) => (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </PageStyles>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
